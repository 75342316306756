import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Button, ContactUsForm, SocialMediaLinks, TrustBox } from 'components';
import ScrollToTopHigherOrderComponent from 'react-scroll-up';
import Blob1SVG from 'images/shared/contact_form/blob_1.svg';
import Blob2SVG from 'images/shared/contact_form/blob_2.svg';
import Blob3SVG from 'images/shared/contact_form/blob_3.svg';
import ScrollToTopSVG from 'images/shared/contact_form/scroll_to_top.svg';

const ContactUs = ({ location, viewportWidth }) => {
  return (
    <Wrapper id="contact-us">
      <Blob1 src={Blob1SVG} alt="red and yellow blob" />
      <Blob2 src={Blob2SVG} alt="red, purple and yellow blob" />
      <Blob3 src={Blob3SVG} alt="yellow blob" />
      <TrustBox
        style={{ marginBottom: '20px' }}
        data={{
          'data-locale': 'en-US',
          'data-template-id': '5406e65db0d04a09e042d5fc',
          'data-businessunit-id': '601a87b799da2200016d6fbd',
          'data-style-height': '28px',
          'data-style-width': '100%',
          'data-theme': 'dark'
        }}
      />
      <p>
        Have a question about team building or event management,
        {viewportWidth > 610 && <br />} or would you like to arrange a free demo of our
        {viewportWidth > 610 && <br />}online team building activities?
      </p>
      <p>We would love to hear from you.</p>
      <p>Contact us today and we&apos;ll respond today.</p>
      <ContactUsFormAndInfoWrapper>
        <ContactUsFormWrapper>
          <p>Send us a message</p>
          <ContactUsForm location={location} />
        </ContactUsFormWrapper>
        <Info>
          <p>Drop us a line</p>
          <InfoEntries>
            <div
              style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
              <InfoEntry>
                <p>Phone:</p>
                <p>
                  Dublin: &nbsp;
                  <a
                    href="tel:+35315545784"
                    style={{ color: '#fff', textDecoration: 'underline' }}>
                    +353 1 554 5784
                  </a>
                </p>
                <p>
                  Belfast: &nbsp;
                  <a
                    href="tel:00442896205700"
                    style={{ color: '#fff', textDecoration: 'underline' }}>
                    +44 28 9620 5700
                  </a>
                </p>
              </InfoEntry>
              <InfoEntry>
                <p>Email:</p>
                <p>info@dynamicevents.ie</p>
              </InfoEntry>
              <InfoEntry>
                <p>Office Address:</p>
                <p>
                  Unit 8 Burgage House,
                  <br />
                  Blessington, Co.Wicklow.
                </p>
              </InfoEntry>
              <InfoEntry>
                <p>Follow Us</p>
                <SocialMediaLinks />
              </InfoEntry>
            </div>
            <Button
              handleClick={() => navigate('/venue-partners')}
              text="Venue Partners"
              ariaLabel="venue partners"
              buttonColor="--dynamic-dark-grey"
              width="200px"
              height="40px"
              buttonHoverColor="--dynamic-black"
              buttonPressedColor="--dynamic-yellow"
              fontPressedColor="--dynamic-dark-grey"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
            />
            <InfoEntry>
              <p>Download Company Brochure</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  height: '40px',
                  maxWidth: '464px',
                  position: 'relative',
                  bottom: '5px'
                }}>
                <a href="https://www2.dynamicevents.ie/brochure" target="_blank">
                  <Button
                    text="Download"
                    ariaLabel="download brochure"
                    buttonColor="--dynamic-dark-grey"
                    width="121px"
                    height="40px"
                    buttonHoverColor="--dynamic-black"
                    buttonPressedColor="--dynamic-yellow"
                    fontPressedColor="--dynamic-dark-grey"
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                  />
                </a>
                <ScrollToTopHigherOrderComponent
                  style={{
                    cursor: 'pointer',
                    transitionDuration: '0.2s',
                    transitionTimingFunction: 'linear',
                    transitionDelay: '0s',
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }}
                  easing="easeInOutQuint"
                  duration={1100}
                  showUnder={0}>
                  <ScrollToTopIcon>
                    <img src={ScrollToTopSVG} alt="" />
                  </ScrollToTopIcon>
                </ScrollToTopHigherOrderComponent>
              </div>
            </InfoEntry>
          </InfoEntries>
        </Info>
      </ContactUsFormAndInfoWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: var(--dynamic-blue);
  position: relative;
  padding-top: 2.95em;
  padding-bottom: 4.5em;

  > * {
    position: relative;
    z-index: 1;
  }

  > p:nth-of-type(1),
  > p:nth-of-type(2),
  > p:nth-of-type(3) {
    color: #fff;
    font-size: 1.55rem;
    padding: 0 3.2%;
    text-align: center;
    letter-spacing: 0.01em;

    @media screen and (min-width: 360px) {
      font-size: 1.75rem;
    }
  }

  > p:nth-of-type(1),
  > p:nth-of-type(2) {
    font-family: adelle, serif;
  }

  > p:nth-of-type(2) {
    margin-top: 0.525em;
  }

  > p:nth-of-type(3) {
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.25rem;
    margin-top: 0.525em;
  }
`;

const ContactUsFormAndInfoWrapper = styled.div`
  font-family: 'proxima-nova', sans-serif;
  margin: 3.35em auto 0;
  padding: 0 3.2%;

  @media screen and (min-width: 580px) {
    padding: 0;
  }

  @media screen and (min-width: 936px) {
    display: grid;
    grid-template-columns: 60.14% 39.86%;
    max-width: 1161px;
  }
`;

const ContactUsFormWrapper = styled.div`
  margin: 0 auto 2.5em;
  max-width: 540px;
  letter-spacing: 0.01em;

  @media screen and (min-width: 936px) {
    margin: 0 13.781223083548666%;
  }

  @media screen and (min-width: 1300px) {
    transform: translateX(-13.781223083548666%);
  }

  > p:nth-of-type(1) {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 700;
  }
`;

const Info = styled.div`
  margin: 0 auto;
  max-width: 540px;
  letter-spacing: 0.01em;

  > p:nth-of-type(1) {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 700;
  }

  @media screen and (min-width: 936px) {
    margin: initial;
    max-width: initial;
    padding-right: 25px;
  }
`;

const InfoEntries = styled.div`
  margin-top: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 510px;

  @media screen and (min-width: 936px) {
    height: 605px;
  }
`;

const InfoEntry = styled.div`
  letter-spacing: 0.01em;

  > p:nth-of-type(1) {
    color: var(--dynamic-dark-grey);
    font-size: 1.25rem;
    font-weight: 700;
  }

  > p:nth-of-type(2),
  > p:nth-of-type(3) {
    color: #fff;
    font-size: 1rem;
    margin-top: 0.5em;
  }

  a {
    text-decoration: none;
  }

  > div {
    margin-top: 0.8em;
  }
`;

const ScrollToTopIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;

  img {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &:hover {
    transform: scale3d(1.2, 1.2, 1.2);
    transition: transform 0.2s ease-in-out;
  }

  &:not(:hover) {
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s ease-in-out;
  }
`;

const Blob1 = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const Blob2 = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

const Blob3 = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ContactUs);
